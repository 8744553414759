
import { defineComponent, onMounted, ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ProductReservationResource from '@/resources/ProductReservationResource';
import Form from '@/components/ProductReservation/Form.vue';
import useForm from '@/composables/use-form';

export default defineComponent({
    components: { Form },

    setup() {
        const route = useRoute();
        const router = useRouter();
        const reservation = reactive({
            product: {},
            event: {},
            user: {},
            reserved_from: null,
            reserved_to: null,
        });
        const form = useForm(reservation, ProductReservationResource);
        const isLoading = ref(true);
        const { isSaving, update  } = form;

        onMounted(() => {
            ProductReservationResource.get({id: Number(route.params.id)}).then((response) => {
                Object.assign(reservation, response);
            });

            setTimeout(() => {
                isLoading.value = false;
            }, 2000);
        });

        function submit() {
            update().then(() => {
                router.go(-1);
            });
        }

        return {
            reservation,
            form,
            isLoading,
            isSaving,
            submit,
        };
    },
});
